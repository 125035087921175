const prefix = "ls.";

const get = (key) => JSON.parse(localStorage.getItem(prefix + key));
const set = (key, value) =>
  localStorage.setItem(prefix + key, JSON.stringify(value));
const remove = (key) => localStorage.removeItem(prefix + key);

const subStoreGet = (storeKey, key) => (get(storeKey) || {})[key];
const subStoreSet = (storeKey, key, value) => {
  const subStore = get(storeKey);
  set(storeKey, { ...subStore, [key]: value });
};
const subStoreRemove = (storeKey, key) => {
  const subStore = get(storeKey);
  const newSubStore = { ...subStore };
  delete newSubStore[key];
  set(storeKey, newSubStore);
};

const subStore = (storeKey) => ({
  get: (key) => subStoreGet(storeKey, key),
  set: (key, value) => subStoreSet(storeKey, key, value),
  remove: (key) => subStoreRemove(storeKey, key),
});

export default { get, set, remove, subStore };
